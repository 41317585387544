import React, { Component } from 'react';
import styles from './WindSparkline.module.css';
import {FlexibleWidthXYPlot, XAxis, YAxis, HorizontalGridLines, LineSeries, AreaSeries} from 'react-vis';
// import {curveCatmullRom} from 'd3-shape';
import '../../../node_modules/react-vis/dist/style.css';
// import TimeAgo from 'react-timeago';

const defaultYDomain = [0,15];
const defaultHistorySeconds = 3 * 60 * 60 * 1000;

export default class WindSparkline extends Component {

  state = {
    yDomain: defaultYDomain,
    avgData: [],
    bandData: [],
    ticks: []
  };

  async componentDidMount() {
  }

  async componentDidUpdate(prevProps) {
    if ( this.props.data !== prevProps.data ) {
      await this.prepareData();
    }
  }

  async prepareData() {
    if (this.props.data) {

      let data = this.props.data;

      let avgData = [];
      let bandData = [];
      let ticks = [];

      let count = 0;
      let currentHour = -1;
      let cutOff = new Date().getTime() - defaultHistorySeconds;

      for (let i=0; i < data.length; i++) {

        let date = new Date(data[i].obsTimeUtc);
        let x = date.getTime();

        if (x > cutOff) {

          if (currentHour < date.getHours()) {
            if (currentHour >= 0) ticks.push(x);
            currentHour = date.getHours();
          }

          avgData.push({x, y: data[i].windspeedAvg});
          bandData.push({x, y: data[i].windspeedAvg, y0: data[i].windgustAvg})
          count++;
        }
      }

      // for (let i=Math.max(data.length - 36, 0); i < data.length; i++) {
      //   // let x = `${date.getHours()}:${date.getMinutes()}`
      //   // let x = new Date(data[i].obsTimeUtc).getTime();
      //   let date = new Date(data[i].obsTimeUtc);
      //   let x = date.getTime();

      //   if (currentHour < date.getHours()) {
      //     if (currentHour >= 0) ticks.push(x);
      //     currentHour = date.getHours();
      //   }

      //   avgData.push({x, y: data[i].windspeedAvg});
      //   bandData.push({x, y: data[i].windspeedAvg, y0: data[i].windgustAvg})
      //   count++;
      // }

      // Update Y-domain
      let {min, max} = bandData.reduce((acc, row) => { return {min: Math.min(acc.min, row.y), max: Math.max(acc.max, row.y0)} }, {min: Infinity, max: -Infinity})

      // console.log([min, max], [Math.floor(min/5)*5, Math.ceil(max/5)*5])

      max = Math.max(Math.ceil(max/5)*5, defaultYDomain[1]);

      if (max > defaultYDomain[1]) {
        min = max - (defaultYDomain[1]-defaultYDomain[0])
      } else {
        min = Math.min(Math.floor(min/5)*5, defaultYDomain[0]);
      }

      // Set result
      this.setState({ avgData, bandData, ticks, yDomain: [min, max] })

      // console.log('avgData', avgData)
      // console.log('bandData', bandData)
      console.log('ticks', ticks)
      // console.log([min, max])

    }
  }

  formatHours(v) {
    let date = new Date(v); 
    let hours = date.getHours();
    let meridian = 'pm'


    if (hours > 12) {
      hours -= 12;
      meridian = 'pm' 
    } else if (hours < 12) {
      if (hours === 0) hours = 12;
      meridian = 'am'
    }

    return `${hours} ${meridian}`
  }

  render() {


    let data = this.props.data;
    if (!data || data.length < 1) return (<span>Loading ...</span>)


    return(
      <div className={styles.container}>
        <FlexibleWidthXYPlot
          height={100}
          yDomain={this.state.yDomain}
          margin={{left: 0, right: 25, top: 10, bottom: 25}}>
          <HorizontalGridLines style={{stroke: '#ddd'}} />
          <AreaSeries
            curve={'curveMonotoneX'}
            stroke={'#C62EFF'}
            fill={'#C62EFF'}
            opacity={.7}
            data={this.state.bandData}
          />
          <LineSeries
            stroke={'#333'}
            strokeWidth="1"
            curve={'curveMonotoneX'}
            data={this.state.avgData}/>
          <XAxis 
            tickSize={3}
            tickValues={this.state.ticks}
            tickFormat={v => {return this.formatHours(v)}}
            style={{
              line: {stroke: 'none'},
            }}
          />
          <YAxis
            orientation={'right'}
            tickSize={0}
            tickTotal={3}
            style={{
              line: {stroke: 'none'},
            }}
          />
        </FlexibleWidthXYPlot>
      </div>
    )
  }
}
