import React from 'react';
import './App.css';

import { CurrentWindConditions } from './components/CurrentWindConditions'

let apiKey = 'e1f10a1e78da46f5b10a1e78da96f525'
let stationIds = ['KCADALYC37', 'KCADALYC1', 'KCASANFR69', 'KCASANFR99']
// let cache = {current:{}}


function App() {



  return (
    <div className="App">
      <div className="Content">
        <CurrentWindConditions style={{flex: '0 45%'}} apiKey={apiKey} stationId={stationIds[0]} refresh='20' neighborhood='Mussel Rock Coyote' simulate='false'/>
        <CurrentWindConditions style={{flex: '0 45%'}} apiKey={apiKey} stationId={stationIds[1]} refresh='20'/>
        <CurrentWindConditions style={{flex: '0 45%'}} apiKey={apiKey} stationId={stationIds[2]} refresh='20'/>
        <CurrentWindConditions style={{flex: '0 45%'}} apiKey={apiKey} stationId={stationIds[3]} refresh='20' neighborhood='Ocean Beach'/>
      </div>
    </div>
  );
}

export default App;
