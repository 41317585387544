import React, { Component } from 'react';
import styles from './WindDirection.module.css';
import windDial from './wind-dial.svg';
import windMarker from './wind-marker.svg';

export default class WindDirection extends Component {

  static directions16 = ['N','NNE','NE','ENE','E','ESE','SE','SSE','S','SSW','SW','WSW','W','WNW','NW','NNW'];

  static defaultProps = {
    direction: 0,
    status: 'online'
  };

  getDirectionName() {
    return WindDirection.directions16[Math.round(this.props.direction / 22.5) % WindDirection.directions16.length];
  }

  render() {
    let markerStyle = {
       transform: `translateX(-50%) rotate(${this.props.direction - 180}deg)`
    }

    if (this.props.status === 'offline') {
      markerStyle.display = 'none';
    }

    return (
      <div className={styles.container}>
        <div className={styles.layer}>
          <img className={styles.dial} src={windDial} alt=''/>
        </div>
        <div className={styles.markerwrapper} style={markerStyle}>
          <img className={styles.marker} src={windMarker} alt=''/>
        </div>
        <div className={styles.direction}><p>{(this.props.status === 'offline')? String.fromCharCode(0x2012) : this.getDirectionName()}</p></div>
      </div>
    )
  }
}
