// https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html#fetching-external-data 

import React, { Component } from 'react';
import axios, { CancelToken } from 'axios';
import styles from './CurrentWindConditions.module.css';
import { WindDirection } from '../WindDirection';
import { WindSparkline } from '../WindSparkline';
import TimeAgo from 'react-timeago';


export default class CurrentWindConditions extends Component {

  state = {
    identity: null,
    currentData: null,
    intervalId: null,
  };

  updateIdentity() {

    this._requestSourceIdentity  = CancelToken.source();

    axios.get(`https://api.weather.com/v2/pwsidentity?apiKey=${this.props.apiKey}&stationId=${this.props.stationId}&format=json&units=e`, {
      cancelToken: this._requestSourceIdentity.token
    })
    .then((response) => {

      const { data } = response;

      this._requestSourceIdentity = null;

      if (data) {
        this.setState({identity: data});
      }
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
  }

  updateHistory() {
    // https://api.weather.com/v2/pws/observations/all/1day?apiKey=${this.props.apiKey}&stationId=${this.props.stationId}&numericPrecision=decimal&format=json&units=e

    this._requestSourceHistory  = CancelToken.source();

    axios.get(`https://api.weather.com/v2/pws/observations/all/1day?apiKey=${this.props.apiKey}&stationId=${this.props.stationId}&numericPrecision=decimal&format=json&units=e`, {
      cancelToken: this._requestSourceData.token
    })
    .then((response) => {

      console.log(response)
      const { data } = response;

      this._requestSourceHistory = null;

      if (response.status === 200 && data.hasOwnProperty('observations') && data.observations.length > 0) {
        let historyData = [];
        for (let entry of data.observations) { 
          let {obsTimeUtc, winddirAvg} = entry;
          let {windspeedAvg, windgustAvg} = entry.imperial;

          historyData.push({obsTimeUtc, winddirAvg, windspeedAvg, windgustAvg})
        }
        this.setState({historyData});
      }
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })

  }

  updateCurrent() {

    // if (this.props.simulate === 'true') {
    //   return this.simulateData();      
    // }

    this._requestSourceData  = CancelToken.source();

    axios.get(`https://api.weather.com/v2/pws/observations/current?apiKey=${this.props.apiKey}&stationId=${this.props.stationId}&numericPrecision=decimal&format=json&units=e`, {
      cancelToken: this._requestSourceData.token
    })
    .then((response) => {

      // console.log(response)
      const { data } = response;

      this._requestSourceData = null;

      if (response.status === 200 && data.hasOwnProperty('observations') && data.observations.length > 0) {
        let currentData = data.observations[0];
        this.setState({currentData});
      } else if (!this.state.identity) {
        // console.log(this.props.stationId + ' no data')
        this.setState({currentData: null});
        this.updateIdentity();
        this.forceUpdate();
      }
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
  }

  updateData() {
    this.updateCurrent()
    this.updateHistory()
  }

  // simulateData() {

  //   let currentDataDefault = 
  //     {
  //       "stationID": "KCADALYC37",
  //       "obsTimeUtc": "2020-05-26T18:00:01Z",
  //       "obsTimeLocal": "2020-05-26 11:00:01",
  //       "neighborhood": "Mr-Coyote",
  //       "softwareType": "meteobridge",
  //       "country": "US",
  //       "solarRadiation": 805,
  //       "lon": -122.489334,
  //       "realtimeFrequency": null,
  //       "epoch": 1590516001,
  //       "lat": 37.667549,
  //       "uv": 7.2,
  //       "winddir": 290,
  //       "humidity": 82,
  //       "qcStatus": 1,
  //       "imperial": {
  //           "temp": 63.3,
  //           "heatIndex": 63.3,
  //           "dewpt": 57.7,
  //           "windChill": 63.3,
  //           "windSpeed": 0,
  //           "windGust": 4,
  //           "pressure": 29.91,
  //           "precipRate": 0,
  //           "precipTotal": 0,
  //           "elev": 490
  //       }
  //     }

  //   let currentData

  //   if (this.state.currentData == null) {
  //     currentData = currentDataDefault;
  //   } else {
  //     currentData = this.state.currentData;
  //   }

  //   currentData.winddir = (currentData.winddir + 10) % 360;
  //   currentData.imperial.windSpeed = (currentData.imperial.windSpeed + 1) % 26;
  //   currentData.obsTimeLocal = new Date().toISOString();
  //   console.log(`(${currentData.winddir}/${currentData.imperial.windSpeed})`);

  //   this.setState({currentData});
  // }

  componentDidMount() {
    this.updateIdentity()
    this.updateData()
    let intervalId = setInterval(this.updateData.bind(this), this.props.refresh * 1000);
    this.setState({intervalId: intervalId});
  }

  componentWillUnmount() {
    if (this._requestSourceData) {
      this._requestSourceData.cancel();
    }
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
  }

  render() {
    // console.log(this.props.stationId + ' render')
    // console.log(this.state.currentData)
    if (this.state.currentData === null) {

      let neighborhood = this.props.neighborhood;
      if ((neighborhood === undefined) && this.state.identity) neighborhood = this.state.identity.neighborhood;
      let identity = this.state.identity;

      return(
        <div className={styles.container}>

          <div className={styles.title}>
            <p>{(neighborhood)? neighborhood : this.props.stationId}</p>
            <span className={styles.offline}></span><span className={styles.status}>Offline</span>
          </div>
          <div className={styles.layer}>
            <WindDirection status='offline'/>
            <div className={styles.detail}><p>WIND & GUST</p><span> &#8210; / &#8210; mph</span></div>
            <WindSparkline data={this.state.historyData}/>
          </div>

          {(identity)? (
          <div className={styles.location}>
            <span>{identity.ID}</span> - Elev <span>{identity.elevation}</span> ft, <span>{Math.abs(identity.latitude.toFixed(2))}</span> °N, <span>{Math.abs(identity.longitude.toFixed(2))}</span> °W
          </div>):' '}

        </div>
      )
    } else {
      
      let data = this.state.currentData;
      let neighborhood = this.props.neighborhood;
      if (neighborhood === undefined) neighborhood = data.neighborhood;

      return(
        <div className={styles.container}>
          
          <div className={styles.title}>
            <p>{neighborhood}</p>
            <span className={styles.online}></span><span className={styles.status}>Online (updated <TimeAgo date={ data.obsTimeUtc } minPeriod='10'/>)</span><br/>
          </div>
          <div className={styles.layer}>
            <WindDirection direction={data.winddir}/>
            <div className={styles.detail}><p>WIND & GUST</p><span>{data.imperial.windSpeed?data.imperial.windSpeed.toFixed(1):0} / {data.imperial.windGust?data.imperial.windGust.toFixed(1):0} mph</span></div>
            <WindSparkline data={this.state.historyData}/>
          </div>
          <div className={styles.location}>
            <span>{data.stationID}</span> - Elev <span>{data.imperial.elev}</span> ft, <span>{Math.abs(data.lat.toFixed(2))}</span> °N, <span>{Math.abs(data.lon.toFixed(2))}</span> °W
          </div>
        </div>
      )
    }
  }
}
